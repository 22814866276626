import React from "react"
// import {Link} from "gatsby"
// import { RiHeart2Line } from "react-icons/ri";

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <a className="inline" href="/privacy-policy">Privacy Policy</a>
      <p className="inline-pad">|</p>
      {/* <a className="inline" href="/support">Client Support</a>
      <p className="inline-pad">|</p> */}
      {/* <a className="inline" href="/company">Company</a>
      <p className="inline-pad">|</p> */}
      {/* <a className="inline" href="/about">Mission Statement</a>
      <p className="inline-pad">|</p> */}
      <p className="inline">© Dornfeld Capital</p>
      {/* <p>A GatsbyJS Starter for Netlify CMS, Made with <span className="icon -love"><RiHeart2Line/></span> by <Link to="/">Stackrole.com</Link></p> */}
    </div>
  </footer>
)

export default Footer